import React from 'react';

import "leaflet/dist/leaflet.css";
import 'antd/dist/antd.css'
import './Style/App.scss';

import {Map} from "./Map/Map";

function App() {
  return (<Map/>
  );
}

export default App;
