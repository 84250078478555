import L from "leaflet";

import schoolSVG from "../images/svg/icon-shcool_20x21.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [20, 20],
        tooltipAnchor: [10, -10]
    },
});

// @ts-ignore
export const schoolIcon = new LeafIcon({iconUrl: schoolSVG});
