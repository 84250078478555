import L from "leaflet";

import repairSVG from "../images/svg/icon-repair_20x21.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [20, 20],
        tooltipAnchor: [10, -10]
    },
});

// @ts-ignore
export const repairIcon = new LeafIcon({iconUrl: repairSVG});
