export const niko_damage_data = {
  type: "FeatureCollection",
  name: "niko-damage-data",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        category: "Заклади соціального захисту",
        organ: "Комунальна установа \"Міський геріатричний будинок милосердя імені Святого Миколая\"",
        address: "м. Миколаїв, вул. 2 Набережна 1-Д",
        latitude: 46.9666691,
        longitude: 32.0488381,
        notes: "7 будівель",
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "<p>1. Житловий корпус.</p><p>2. Адміністративно-побутовий корпус.</p><p>3. Квартира №3 вул. 2-га Набережна 1</p><p>4. Каплиця Святого Миколая Будівлі знаходяться на березі річки</p>",
        damag_desc: "<p>1.В жилому корпусі пошкоджено: Вікон – 8 шт., Дверей - 1 шт.</p><p>2. В адміністративно – побутовому корпусі пошкоджено: Вікон – 7 шт. Дверей – 1 шт. Стеля – 5 кв.м. Криша – 10 кв.м.</p><p>3.Квартира №3, 2-га Набережна 1 пошкоджено Вікон - 1шт.</p><p>4. Каплиця Святого Миколая пошкоджено Купол каплиці – 10 м2. Пошкоджено: Тіньовий навіс – дах Кімната музею – вибиті вікна Вуличний склад –вибиті вікна</p>",
        rest_cost: "205,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0488381,
          46.9666691
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        category: "Заклади соціального захисту",
        organ: "Управління соціальних виплат і компенсацій Корабельного району",
        address: "м. Миколаїв, вул. Новобудівна, 1/1",
        latitude: 46.86219647798071,
        longitude: 32.015708709598,
        notes: "1 будівля",
        ownership: "Комунальна",
        balancer: "ДПСЗН",
        obj_desc: "Одноповерхова адміністративна будівля",
        damag_desc: "<p>Каб.№1 - повністю вибите скло у вікні, пошкоджена рама,вікно потребує заміни;</p><p>Каб.№3 - пошкоджена рама вікна; Каб.№4 - повело раму погано закривається вікно; Каб.№7 - пошкод-ження рами двох вікон, вирвано профіль, потребують заміни;</p><p>Каб.№ 11 - повело раму погано закривається вікно;</p><p>Каб.№ 15 -вибито скло у вікні, пошкоджена рама, вікно потребує заміни. Коридор - двері повело, не зачиняються, потребують заміни</p>",
        rest_cost: "115,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015708709598,
          46.86219647798071
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        category: "Заклади соціального захисту",
        organ: "Управління соціальних виплат і компенсацій Інгульського району",
        address: "м. Миколаїв, вул. Миколаївська, 26/6",
        latitude: 46.94109923789689,
        longitude: 32.04666224675496,
        notes: "1 будівля",
        ownership: "Комунальна",
        balancer: "ДПСЗН",
        obj_desc: "Адміністративна будівля",
        damag_desc: "Пошкодження 23 вікна, 2 металопластикові двері пробиті наскрізь.",
        rest_cost: "270,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04666224675496,
          46.94109923789689
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        category: "Заклади охорони здоров’я",
        organ: "КНП \"Миколаївський обласний центр онкології\" Миколаївської обласної ради",
        address: "м. Миколаїв, вул. Миколаївська, 18",
        latitude: 46.9447088,
        longitude: 32.0462408,
        notes: null,
        ownership: "Комунальна",
        balancer: "Миколаївська обласна рада",
        obj_desc: "?",
        damag_desc: "Пошкоджено 84 вікна, 2 металопластикові двері",
        rest_cost: "1 320,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0462408,
          46.9447088
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        category: "Заклади охорони здоров’я",
        organ: "КНП \"Обласна офтальмологічна лікарня\" Миколаївської обласної ради",
        address: "м. Миколаїв, вул. Театральна, 10",
        latitude: 46.94562911276316,
        longitude: 32.04481711742914,
        notes: null,
        ownership: "Комунальна",
        balancer: "Миколаївська обласна рада",
        obj_desc: "4 поверховий корпус",
        damag_desc: "Пошкоджено 36 вікон",
        rest_cost: "540,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04481711742914,
          46.94562911276316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Міська лікарня №3\"",
        address: "м. Миколаїв, вул. Космонавтів, 97",
        latitude: 46.9780845,
        longitude: 32.0837266,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "6 поверхова будівля",
        damag_desc: "Пошкоджено 65 вікон",
        rest_cost: "975,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0837266,
          46.9780845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        category: "Заклади охорони здоров’я",
        organ: "КНП \"Миколаївська міська лікарня № 4\" акт від 12.07.2022",
        address: "м. Миколаїв, вул. Адмірала Макарова, 1",
        latitude: 46.973117323405,
        longitude: 31.974701119872947,
        notes: null,
        ownership: null,
        balancer: "Миколаївська міська рада",
        obj_desc: "?",
        damag_desc: "вибито - 115 вікон, пошкоджені двері -5 (внутрішні), в кабінетах та палатах пошкоджені стіни, пошкоджений утеплювач на фасаді, пошкоджений парапет на покрівлі, пошкоджений апарат ШВЛ (міндрей) -320 тис. грн. ",
        rest_cost: "уточнюється"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974701119872947,
          46.973117323405
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Міська лікарня №5\"",
        address: "м. Миколаїв, просп. Богоявленський, 336",
        latitude: 46.858222,
        longitude: 32.0167493,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "9 000,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0167493,
          46.858222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Міська дитяча лікарня № 2\"",
        address: "м. Миколаїв, вул. Рюміна, 5",
        latitude: 46.970742,
        longitude: 31.9784107,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "30,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9784107,
          46.970742
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Міська дитяча лікарня № 2\"",
        address: "м. Миколаїв, вул. Космонавтів, 144",
        latitude: 46.96943415330286,
        longitude: 32.07913735387368,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "30,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07913735387368,
          46.96943415330286
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Центр первинної медико-санітарної допомоги № 2\"",
        address: "м. Миколаїв, вул. Космонавтів, 57",
        latitude: 46.94297799722976,
        longitude: 32.05796873570003,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "130,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05796873570003,
          46.94297799722976
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Центр первинної медико-санітарної допомоги № 2\"",
        address: "м. Миколаїв, вул. Космонавтів, 144",
        latitude: 46.96917601944109,
        longitude: 32.07893420401744,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "уточнюється"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07893420401744,
          46.96917601944109
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Центр первинної медико-санітарної допомоги № 4\"",
        address: "м. Миколаїв, вул. Адміральська, 6",
        latitude: 46.9774835,
        longitude: 31.9876659,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9876659,
          46.9774835
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 14,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Центр первинної медико-санітарної допомоги № 4\"",
        address: "м. Миколаїв, пров. Київський, 1",
        latitude: 47.0007516,
        longitude: 31.998344,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "?",
        damag_desc: "Потребує відновлення",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998344,
          47.0007516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Пологовий будинок №1\" 21.03.2022",
        address: "м. Миколаїв, вул. 2 Екіпажна, 5",
        latitude: 46.97362239628813,
        longitude: 32.01958630247074,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "<p>Стара консультація;</p><p>конференцзал;</p><p>кабінет с/х в приміщенні пральні</p>",
        damag_desc: "<p>Через вибухову хвилю розлетілося скло у вікнах будівлі:</p><p> - старої консультації у кількості 9 шт.;</p><p> - конференцзалу у кількості 4 шт.;</p><p>- у кабінеті с/х в приміщенні пральні у кількості 1 шт.</p><p>Через вибухову хвилю упала стеля у будівлі старої консультації у двох приміщеннях.</p><p>Уламками підірваних боєприпасів пошкоджений шифр у будівлі старої консультації у кількості 6 шт.</p>",
        rest_cost: "700,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01958630247074,
          46.97362239628813
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Пологовий будинок №1\" Жіноча консультація №2",
        address: "м. Миколаїв, вул. Мала Морська, 7",
        latitude: 46.97218204500047,
        longitude: 31.998376250821146,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР (097) 929-27-63",
        obj_desc: "Жіноча консультація",
        damag_desc: "<p>Пошкоджено: 68 вікон, 2 дахи лікарні, 8 стель, 19 стін (з них 45 м2 розбито плитки на стінах);</p><p>Пошкодження водопровідної труби в підвальному приміщенні, що привело до затоплення підвального та архівного приміщення</p>",
        rest_cost: "2 080,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998376250821146,
          46.97218204500047
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 17,
        category: "Заклади охорони здоров’я",
        organ: "КНП ММР \"Пологовий будинок №2\" Жіноча консультація №2",
        address: "м. Миколаїв, вул. Космонавтів, 57",
        latitude: 46.94316055395267,
        longitude: 32.0581230016635,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління охорони здоров’я ММР",
        obj_desc: "5 поверхова будівля",
        damag_desc: "Пошкоджено 32 вікна",
        rest_cost: "680,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0581230016635,
          46.94316055395267
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18,
        category: "Заклади охорони здоров’я",
        organ: "КНП Миколаївський обласний Будинок дитини",
        address: "м. Миколаїв, вул. Курортна, 7-Б",
        latitude: 46.95535874223756,
        longitude: 31.954931091987046,
        notes: null,
        ownership: null,
        balancer: "?",
        obj_desc: "?",
        damag_desc: "<p>вибито скло - 26 великих вікон, 4 дверей), пошкоджена душева кабінка та огорожа (металопрофіль - 100 м. пробита осколками)</p>",
        rest_cost: "1 000,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954931091987046,
          46.95535874223756
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 19,
        category: "Заклади охорони здоров’я",
        organ: "КНП Миколаївська обласна клінічна лікарня",
        address: "м. Миколаїв, вул. Київська, 1",
        latitude: 46.9533566,
        longitude: 31.9435692,
        notes: null,
        ownership: null,
        balancer: "Миколаївська обласна рада",
        obj_desc: "?",
        damag_desc: "вибито скло - 150 вікон, пошкоджені в середині двору повітряні мережі опалення та дах будівлі та підстанція ТП-292",
        rest_cost: "1 000,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9435692,
          46.9533566
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        category: "Заклади освіти",
        organ: "ЗДО № 10",
        address: "м. Миколаїв, вул. Миколаївська, 24-А",
        latitude: 46.942076472620705,
        longitude: 32.04670135390731,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено вікна -6; вхідні двері -3",
        rest_cost: "60,21k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04670135390731,
          46.942076472620705
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 21,
        category: "Заклади освіти",
        organ: "ЗДО №17",
        address: "м. Миколаїв, вул. Космонавтів, 144 Б",
        latitude: 46.96960178336822,
        longitude: 32.07727606740893,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>- пошкоджено дах (4 вирви)- відремонтують самі;</p><p>- вибито 56 вікон; -вибито балконні двері -7;</p><p>- двері в групі-1;</p><p>- Пошкоджено підвіконня- 6;</p><p>- вибито 2 вхідних дверей;</p><p>- у спортзалі натяжна стеля з діркою;</p><p>- розбито два дзеркала</p>",
        rest_cost: "715,38k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07727606740893,
          46.96960178336821
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 22,
        category: "Заклади освіти",
        organ: "ЗДО №23",
        address: "м. Миколаїв, вул. Генерала Карпенка, 1",
        latitude: 46.9525942,
        longitude: 31.9533344,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 23 вікна",
        rest_cost: "35,59k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9533344,
          46.9525942
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 23,
        category: "Заклади освіти",
        organ: "ЗДО №37",
        address: "м. Миколаїв, вул. Театральна, 51-А",
        latitude: 46.945148961290336,
        longitude: 32.04922597375101,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>- вибито 76 вікон;</p><p>- вибито 2 вхідних дверей;</p><p>- вибито скло у 2 вхідних дверях;</p><p>- пошкоджено дах</p>",
        rest_cost: "1 239,78k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04922597375101,
          46.945148961290336
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 24,
        category: "Заклади освіти",
        organ: "ЗДО №64",
        address: "м. Миколаїв, вул. Крилова, 7-Б",
        latitude: 46.95973192664866,
        longitude: 31.952213801340505,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>- пошкоджено : 47 вікна;</p><p>- дверей -6</p>",
        rest_cost: "1 328,25k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952213801340505,
          46.95973192664866
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 25,
        category: "Заклади освіти",
        organ: "ЗДО № 78",
        address: "м. Миколаїв, вул. Курортна, 1",
        latitude: 46.954281344110214,
        longitude: 31.95122406688743,
        notes: null,
        ownership: null,
        balancer: "Управління освіти ММР",
        obj_desc: "?",
        damag_desc: "вибито скло - 4 вікна,",
        rest_cost: "80,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95122406688743,
          46.954281344110214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        category: "Заклади освіти",
        organ: "ЗДО №95",
        address: "м. Миколаїв, вул. Космонавтів, 67-А",
        latitude: 46.95204489707765,
        longitude: 32.066858767878884,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 2 вікна",
        rest_cost: "32,25k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066858767878884,
          46.95204489707765
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 27,
        category: "Заклади освіти",
        organ: "ЗДО № 110",
        address: "м. Миколаїв, вул. Рибна, 4",
        latitude: 46.8642123,
        longitude: 32.013801,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "заклад освіти",
        damag_desc: "<p>пошкоджено 2 вікна;</p><p>вхідні двері-1</p>",
        rest_cost: "58,64k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013801,
          46.8642123
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 28,
        category: "Заклади освіти",
        organ: "ЗДО №125",
        address: "м. Миколаїв, вул. Океанівська, 6",
        latitude: 46.858424060874675,
        longitude: 32.00768362310112,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 12 вікон;</p><p>пошкоджено протипожежні двері – 2</p>",
        rest_cost: "126,15k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00768362310112,
          46.85842406087467
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 29,
        category: "Заклади освіти",
        organ: "ЗДО №126",
        address: "м. Миколаїв, просп. Миру, 64-Б",
        latitude: 46.94151751907064,
        longitude: 32.06031672048697,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 10 вікон",
        rest_cost: "127,59k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06031672048697,
          46.94151751907064
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 30,
        category: "Заклади освіти",
        organ: "ЗДО №130",
        address: "м. Миколаїв, вул. Знаменська, 5-А",
        latitude: 46.91561822573717,
        longitude: 32.07870434375633,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 44 вікна",
        rest_cost: "779,64k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07870434375633,
          46.91561822573717
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 31,
        category: "Заклади освіти",
        organ: "ЗДО №131",
        address: "м. Миколаїв, вул. Зої Космодем’янської, 12-А",
        latitude: 46.9172541,
        longitude: 32.0491918,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 16 вікон",
        rest_cost: "63,38k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0491918,
          46.9172541
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 32,
        category: "Заклади освіти",
        organ: "ЗДО №134",
        address: "м. Миколаїв, вул. Генерала Попеля, 176-А",
        latitude: 46.85167743500108,
        longitude: 32.01728024337242,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 12 вікон",
        rest_cost: "72,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01728024337242,
          46.85167743500108
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 33,
        category: "Заклади освіти",
        organ: "ЗДО №139",
        address: "м. Миколаїв, вул. Океанівська, 28-А",
        latitude: 46.85543358636693,
        longitude: 32.021091246202246,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 4 вікна",
        rest_cost: "46,12k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021091246202246,
          46.85543358636693
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 34,
        category: "Заклади освіти",
        organ: "ЗДО №140",
        address: "м. Миколаїв, вул. Глинки, 7-А",
        latitude: 46.86604538496484,
        longitude: 32.0097906781039,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 1 вікно",
        rest_cost: "3,30k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0097906781039,
          46.86604538496484
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 35,
        category: "Заклади освіти",
        organ: "ЗДО №141",
        address: "м. Миколаїв, просп. Героїв України, 85",
        latitude: 47.0141157,
        longitude: 31.9974434,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 4 вікна",
        rest_cost: "69,52k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 36,
        category: "Заклади освіти",
        organ: "МГ №2",
        address: "м. Миколаїв, вул. Адміральська, 24",
        latitude: 46.97414407172107,
        longitude: 31.999972209065444,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 2 вікна",
        rest_cost: "4,60k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999972209065444,
          46.97414407172107
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 37,
        category: "Заклади освіти",
        organ: "МГ №33",
        address: "м. Миколаїв, вул. Океанівська, 12",
        latitude: 46.857949673728626,
        longitude: 32.01089128280649,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 68 вікон",
        rest_cost: "1 326,85k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01089128280649,
          46.857949673728626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 38,
        category: "Заклади освіти",
        organ: "МГ №44",
        address: "м. Миколаїв, вул. Знаменська, 2/6",
        latitude: 46.91671368949486,
        longitude: 32.076503097146016,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 49 вікон",
        rest_cost: "439,34k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.076503097146016,
          46.91671368949486
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 39,
        category: "Заклади освіти",
        organ: "МГ №49",
        address: "м. Миколаїв, вул. Кобзарська, 15",
        latitude: 46.84582484840408,
        longitude: 32.01232755141582,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 2 вікна",
        rest_cost: "7,79k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01232755141582,
          46.84582484840408
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 40,
        category: "Заклади освіти",
        organ: "МГ №64",
        address: "м. Миколаїв, вул. Архітектора Старова, 6-Г",
        latitude: 47.016921,
        longitude: 32.005031,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Вибито 32 вікна",
        rest_cost: "306,91k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005031,
          47.016921
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 41,
        category: "Заклади освіти",
        organ: "МЛ №42",
        address: "м. Миколаїв, вул. Електронна, 73",
        latitude: 46.9680462,
        longitude: 32.0737281,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Вибито 20 вікон Пошкоджено дах",
        rest_cost: "67,04k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0737281,
          46.9680462
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 42,
        category: "Заклади освіти",
        organ: "МЛ №60",
        address: "м. Миколаїв, вул. Чорноморська 1-А",
        latitude: 46.9900052891501,
        longitude: 31.948324033726745,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 129 вікон;</p><p>двері металеві – 1;</p><p>покрівля 30 кв.м;</p><p>зовнішня стіна 54 кв.м;</p><p>тротуарна плитка 4 кв.м;</p><p>ушкоджена залізобетонна опора- 1</p>",
        rest_cost: "578,02k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948324033726745,
          46.9900052891501
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 43,
        category: "Заклади освіти",
        organ: "Миколаївський юридичний ліцей",
        address: "м. Миколаїв, просп. Миру, 23-Г",
        latitude: 46.9391957,
        longitude: 32.056469,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 32 вікна",
        rest_cost: "274,50k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.056469,
          46.9391957
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 44,
        category: "Заклади освіти",
        organ: "Миколаївський економічний ліцей №1",
        address: "м. Миколаїв, вул. Океанівська, 9",
        latitude: 46.859158,
        longitude: 32.0080834,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 20 вікон;</p><p>пошкоджено 3 двері</p>",
        rest_cost: "385,36k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0080834,
          46.859158
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 45,
        category: "Заклади освіти",
        organ: "Миколаївський економічний ліцей № 2",
        address: "м. Миколаїв, вул. Адміральська, 24",
        latitude: 46.97399149946353,
        longitude: 31.999613509345465,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено: 161 склопакет</p><p>1 двері</p>",
        rest_cost: "418,71k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999613509345465,
          46.97399149946353
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 46,
        category: "Заклади освіти",
        organ: "Професійний промисловий ліцей",
        address: "м. Миколаїв, вул. Вінграновського, 62",
        latitude: 46.9595486,
        longitude: 32.0764864,
        notes: null,
        ownership: "Державна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 115 вікон</p><p>частково відлетіла стеля у майстерні</p><p>пошкоджено дах.</p>",
        rest_cost: "5 048,88k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0764864,
          46.9595486
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 47,
        category: "Заклади освіти",
        organ: "Миколаївський професійний ліцей будівництва та сфери послуг",
        address: "м. Миколаїв, Заводська площа, 3",
        latitude: 46.94906982100981,
        longitude: 31.99272233054291,
        notes: "гуртожиток СП \"Знання\"",
        ownership: "Державна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 54 вікна, двері",
        rest_cost: "704,64k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99272233054291,
          46.94906982100981
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 48,
        category: "Заклади освіти",
        organ: "Миколаївський професійний машинобудівний ліцей",
        address: "м. Миколаїв, вул. Космонавтів, 66",
        latitude: 46.939516009594485,
        longitude: 32.04519246826158,
        notes: null,
        ownership: "Державна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>Пошкоджено 122 вікна</p><p>балконні двері – 3</p><p>двері – 3</p><p>дах (латають власними силами)</p>",
        rest_cost: "871,91k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04519246826158,
          46.93951600959449
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 49,
        category: "Заклади освіти",
        organ: "ЗЗСО №11",
        address: "м. Миколаїв, вул. Китобоїв, 3",
        latitude: 46.9426515,
        longitude: 32.0609255,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 35 вікон</p><p>пошкоджено дах</p>",
        rest_cost: "463,20k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0609255,
          46.9426515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 50,
        category: "Заклади освіти",
        organ: "ЗЗСО № 17 акт від 29.06.2022",
        address: "м. Миколаїв, вул. Крилова, 12/6",
        latitude: 46.95950451685364,
        longitude: 31.955192120132455,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 11 вікон +60, 12 внутрішніх дверей, пошкоджена стеля в класах на 3-му поверсі, пошкоджено, знесено блискавкозахист, частково пошкоджена покрівля",
        rest_cost: "2 740,61k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955192120132455,
          46.95950451685364
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 51,
        category: "Заклади освіти",
        organ: "ЗЗСО №19",
        address: "м. Миколаїв, вул. Передова, 11-А",
        latitude: 46.9478014,
        longitude: 32.0299196,
        notes: null,
        ownership: "Комунальна",
        balancer: "управління освіти ММР",
        obj_desc: "заклад освіти",
        damag_desc: "<p>пошкоджено дах</p><p>пошкоджена стеля в кабінеті</p>",
        rest_cost: "150,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0299196,
          46.9478014
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 52,
        category: "Заклади освіти",
        organ: "ЗЗСО №20",
        address: "м. Миколаїв, вул. Космонавтів, 70",
        latitude: 46.941626973754026,
        longitude: 32.05160987986754,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Вибито 22 вікна Пошкоджено дах",
        rest_cost: "647,52k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05160987986754,
          46.941626973754026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 53,
        category: "Заклади освіти",
        organ: "ЗЗСО №30",
        address: "м. Миколаїв, вул. Квітнева, 50",
        latitude: 46.96343053129851,
        longitude: 32.06609985602435,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 2 вікна",
        rest_cost: "4,65k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06609985602435,
          46.96343053129851
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 54,
        category: "Заклади освіти",
        organ: "ЗЗСО №46",
        address: "м. Миколаїв, вул. 9 Поздовжня, 10",
        latitude: 46.9533915,
        longitude: 32.0481405,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 2 вікна",
        rest_cost: "30,10k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0481405,
          46.9533915
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 55,
        category: "Заклади освіти",
        organ: "ЗЗСО №48",
        address: "м. Миколаїв, вул. Генерала Попеля 164",
        latitude: 46.85376852879754,
        longitude: 32.0210850720361,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 34 вікна",
        rest_cost: "80,16k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0210850720361,
          46.85376852879754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 56,
        category: "Заклади освіти",
        organ: "ЗЗСО №50",
        address: "м. Миколаїв, просп. Миру, 50",
        latitude: 46.9452853,
        longitude: 32.0546545,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 42 вікна двері - 1",
        rest_cost: "241,78k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0546545,
          46.94528529999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 57,
        category: "Заклади освіти",
        organ: "ЗЗСО №56",
        address: "м. Миколаїв, вул. Космонавтів, 138-А",
        latitude: 46.96669022219803,
        longitude: 32.07676960727328,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Вибито 5 вікон",
        rest_cost: "50,87k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07676960727328,
          46.96669022219803
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 58,
        category: "Заклади освіти",
        organ: "Миколаївська спеціалізована школа мистецтв і прикладних ремесел експериментальний навчальний заклад всеукраїнського рівня \"Академія ди",
        address: "м. Миколаїв, вул. Олійника, 36",
        latitude: 50.409284840896795,
        longitude: 30.642417325213238,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 1 вікно",
        rest_cost: "12,20k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          30.642417325213234,
          50.40928484089679
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 59,
        category: "Заклади освіти",
        organ: "Палац творчості учнів",
        address: "м. Миколаїв, вул. Адміральська, 31",
        latitude: 46.97411957151712,
        longitude: 31.996541795954798,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 117 вікон",
        rest_cost: "2 375,07k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996541795954794,
          46.97411957151712
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 60,
        category: "Заклади освіти",
        organ: "Миколаївський міський будинок вчителя",
        address: "м. Миколаїв, просп. Центральний, 166",
        latitude: 46.96563304465872,
        longitude: 32.018289606845016,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 1вікно",
        rest_cost: "4,90k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018289606845016,
          46.965633044658716
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 61,
        category: "Заклади освіти",
        organ: "Будинок творчості дітей та юнацтва Інгульського району",
        address: "м. Миколаїв, вул. Космонавтів, 128-А",
        latitude: 46.9603024,
        longitude: 32.0709713,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "пошкоджено 5 вітражних вікон; пошкоджено вікон- 20",
        rest_cost: "217,10k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0709713,
          46.9603024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 62,
        category: "Заклади освіти",
        organ: "Будинок творчості дітей та юнацтва Інгульського району",
        address: "м. Миколаїв, просп. Миру, 57-Б",
        latitude: 46.9402283,
        longitude: 32.0596637,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "?",
        damag_desc: "Уточнюється",
        rest_cost: "уточнюється"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0596637,
          46.9402283
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 63,
        category: "Заклади освіти",
        organ: "Будинок творчості дітей та юнацтва Інгульського району",
        address: "м. Миколаїв, просп. Миру, 17-Г",
        latitude: 46.944231645010774,
        longitude: 32.05194476848282,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "?",
        damag_desc: "Уточнюється",
        rest_cost: "уточнюється"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05194476848282,
          46.944231645010774
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 64,
        category: "Заклади освіти",
        organ: "Миколаївська спеціальна школа для дітей з порушеннями зору",
        address: "м. Миколаїв, просп. Миру, 21-В",
        latitude: 46.9429797,
        longitude: 32.053937,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "-вибито 88 вікон; -пошкоджено дах.",
        rest_cost: "1 522,57k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053937,
          46.9429797
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 65,
        category: "Заклади освіти",
        organ: "Клуб юних моряків з флотилією",
        address: "м. Миколаїв, Інгульський узвіз, 2",
        latitude: 46.9458345,
        longitude: 32.0694946,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 18 вікон",
        rest_cost: "59,88k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0694946,
          46.9458345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 66,
        category: "Заклади освіти",
        organ: "Вище професійне училище суднобудування м. Миколаєва",
        address: "м. Миколаїв, вул. Айвазовського, 6-А",
        latitude: 46.853866899626986,
        longitude: 32.003239775313766,
        notes: null,
        ownership: "Державна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "Пошкоджено 45 вікон",
        rest_cost: "193,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003239775313766,
          46.853866899626986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 67,
        category: "Заклади освіти",
        organ: "ДНЗ \"Миколаївське вище професійне училище технології та дизайну\"",
        address: "м. Миколаїв, вул. Крилова, 7-А",
        latitude: 46.95974908961282,
        longitude: 31.951984337201388,
        notes: null,
        ownership: "Державна",
        balancer: "Управління освіти ММР",
        obj_desc: "Заклад освіти",
        damag_desc: "<p>пошкоджено 18 вікон</p><p>вхідні двері-1</p><p>дах 462 кв.м. (підлатали власними силами)</p>",
        rest_cost: "177,04k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951984337201388,
          46.95974908961283
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 68,
        category: "Заклади освіти",
        organ: "Централізована бухгалтерія при управлінні освіти ММР",
        address: "м. Миколаїв, вул. Мала Морська, 3",
        latitude: 46.97299782537205,
        longitude: 31.998995040613167,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        obj_desc: "Установа освіти",
        damag_desc: "Пошкоджено 1 вікно",
        rest_cost: "4,50k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998995040613167,
          46.97299782537205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 69,
        category: "Заклади освіти",
        organ: "Миколаївський інститут права Національного університету \"Одеська юридична академія\"",
        address: "м. Миколаїв, вул. Генерала Карпенка, 18",
        latitude: 46.9645419,
        longitude: 31.9520475,
        notes: null,
        ownership: null,
        balancer: "Національний університет «Юридична академія» м. Одеса",
        obj_desc: "?",
        damag_desc: "Зруйновано дах майстерні столярної (покрівля шиферна, в шифері дірки, тріщини 642 кв.м. вибиті 6 вікон, пошкоджена покрівля в сараї 36 кв.м, спортивний майданчик 322 кв.м., ворота 8,51 кв.м., шифер на складських приміщеннях та навісах 41 кв.м., знищено 3 секції бетонного паркану",
        rest_cost: "350,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9520475,
          46.9645419
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        category: "Заклади освіти",
        organ: "\"Миколаївський фаховий коледж транспортної інфраструктури Українського державного університету науки і технологій\" (Технікум залізничн",
        address: "м. Миколаїв, вул. Пушкінська, 71",
        latitude: 46.96149942886379,
        longitude: 31.976831094210144,
        notes: null,
        ownership: "Державна",
        balancer: "Український державний університет науки і технологій",
        obj_desc: "?",
        damag_desc: "пошкоджено покрівлю (877 кв.м.), вибиті-276 вікон, пошкоджені бокси (гаражи цегляні), металеві двері(3,5х3,11)-11шт., двері вхідні (2х0,8)- 8 шт, пошкоджен",
        rest_cost: "12 500,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976831094210144,
          46.961499428863796
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 71,
        category: "Заклади культури",
        organ: "ММПК \"Корабельний\"",
        address: "м. Миколаїв, просп. Богоявленський 328",
        latitude: 46.8631073,
        longitude: 32.0184219,
        notes: null,
        ownership: "Комунальна",
        balancer: "ММПК «Корабельний»",
        obj_desc: "Миколаївський міський палац культури «Корабельний»",
        damag_desc: "Загальна площа пошкоджених склопакетів внаслідок обстрілів (28.02.22, 11.04.2022, 05.07.2022) становить близько 3400 м2. Зруйновано 2/3 частини будівлі. Технічна експертиза, проектно-кошторисна документація",
        rest_cost: "1 200,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0184219,
          46.8631073
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 72,
        category: "Заклади культури",
        organ: "Миколаївський міський палац культури і мистецтв Міський центр культури та дозвілля (виставкова зала)",
        address: "м. Миколаїв, вул. Нікольська, 54",
        latitude: 46.974167250280175,
        longitude: 31.99432437950397,
        notes: null,
        ownership: "Комунальна",
        balancer: "Миколаївський міський палац культури і мистецтв",
        obj_desc: "Не житлові приміщення, цокольний поверх багатоквартирного житлового будинку",
        damag_desc: "Пошкоджені вікна та вхідні двері, а саме вибито скло та пошкоджено рами.",
        rest_cost: "240,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99432437950397,
          46.974167250280175
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 73,
        category: "Заклади культури",
        organ: "Кульбакинський будинок культури",
        address: "м. Миколаїв, вул. Веселкова, 38",
        latitude: 46.9125128,
        longitude: 32.0812728,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління з питань культури та охорони культурної спадщини ММР",
        obj_desc: "Будинок культури",
        damag_desc: "Пробитий уламком склопакет в одному з репетиційних приміщень. (27 кабінет)",
        rest_cost: "18,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0812728,
          46.91251279999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 74,
        category: "Заклади культури",
        organ: "КУ Миколаївський зоопарк",
        address: "м. Миколаїв, пл. Леонтовича, 1",
        latitude: 46.9604009,
        longitude: 32.0345456,
        notes: null,
        ownership: "Комунальна",
        balancer: "КУ Миколаївський зоопарк",
        obj_desc: "?",
        damag_desc: "<p>Пошкоджено плиткове покриття біля будівлі \"Острів звірів\" загальною площею 2,5 кв.м</p><p>пошкоджено металеву сітку на вольєрі \"Птиці\" загальною площею 4 кв.м.</p><p>пошкоджено асфальтове покриття, зіпсовано частину огорожі</p>",
        rest_cost: "35,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0345456,
          46.9604009
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 75,
        category: "Заклади культури",
        organ: "Бібліотека-філія №2 Центральної міської бібліотеки для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        address: "м. Миколаїв, вул. Соборна, 12",
        latitude: 46.9645769,
        longitude: 31.9887622,
        notes: null,
        ownership: "Комунальна",
        balancer: "Центральна міська бібліотека для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        obj_desc: "Бібліотека розташована на першому поверсі багатопверхового житлового будинку",
        damag_desc: "В наслідок обстрілів 20.04.2022 пошкоджено 5 вікнон та частково вхідну групу",
        rest_cost: "80,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9887622,
          46.9645769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 76,
        category: "Заклади культури",
        organ: "Бібліотека-філія №5 Центральної міської бібліотеки для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        address: "м. Миколаїв, вул. Південна, 52/3",
        latitude: 46.9478524,
        longitude: 32.0369827,
        notes: null,
        ownership: "Комунальна",
        balancer: "Центральна міська бібліотека для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        obj_desc: "Бібліотека розташована на першому поверсі багатопверхового житлового будинку",
        damag_desc: "12.05.2022 в наслідок бомбордування пошкоджено 6 вікон, трое вхідних дверей, 2 піддашка, ролети",
        rest_cost: "126,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0369827,
          46.9478524
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 77,
        category: "Заклади культури",
        organ: "Бібліотека-філія №8 Центральної міської бібліотеки для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        address: "м. Миколаїв, просп. Корабелів, 12",
        latitude: 46.856102393436565,
        longitude: 32.01172574509435,
        notes: null,
        ownership: "Комунальна",
        balancer: "Центральна міська бібліотека для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        obj_desc: "Бібліотека розташована на першому поверсі багатопверхового житлового будинку",
        damag_desc: "Внаслідок обстрілів 07.05.2022 пошкоджено скляний пандус на прилеглій території бібліотеки. Першочергово необхідно провести технічну експер",
        rest_cost: "100,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01172574509435,
          46.856102393436565
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 78,
        category: "Заклади культури",
        organ: "Бібліотека-філія № 9 ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих",
        address: "м. Миколаїв, пров. Південний, 30",
        latitude: 46.9452346,
        longitude: 32.0290939,
        notes: null,
        ownership: "Комунальна",
        balancer: "ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих м. Миколаєва",
        obj_desc: "Знаходиться на першому поверсі багатоповерхового житлового будинку",
        damag_desc: "10.04.2022 року внаслідок вибухової хвилі вибито два внутрішніх фасадних вікна, розміром 0,6 на 1,3 кв.м. та потріскалось скло у двох вікнах різног",
        rest_cost: "15,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0290939,
          46.9452346
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 79,
        category: "Заклади культури",
        organ: "Бібліотека-філія №10 Центральної міської бібліотеки для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        address: "м. Миколаїв, вул. Космонавтів, 73/7",
        latitude: 46.953575043249785,
        longitude: 32.0676318683346,
        notes: "1 будівля",
        ownership: "Комунальна",
        balancer: "Центральна міська бібліотека для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        obj_desc: "Бібліотека розташована на першому поверсі п’яти верхового житлового будинку",
        damag_desc: "станом на 24.03.2022. В наслідок обстрілів пошкоджено 1 вікно в приміщенні читальної зали",
        rest_cost: "5,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0676318683346,
          46.953575043249785
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 80,
        category: "Заклади культури",
        organ: "Бібліотека-філія №11 Центральної міської бібліотеки для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        address: "м. Миколаїв, просп. Миру, 17-Г/11",
        latitude: 46.9443714,
        longitude: 32.0519112,
        notes: null,
        ownership: "Комунальна",
        balancer: "Центральна міська бібліотека для дітей ім.Ш.Кобера і В.Хоменка м.Миколаєва",
        obj_desc: "Бібліотека розташована на другому поверсі громадської будівлі",
        damag_desc: "В наслідок обстрілів пошкоджено 4 вікна бібліотеки, вхідні двері",
        rest_cost: "30,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0519112,
          46.9443714
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 81,
        category: "Заклади культури",
        organ: "Бібліотека-філія № 12 ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих",
        address: "м. Миколаїв, просп. Миру, 25-А",
        latitude: 46.939546005875115,
        longitude: 32.057562368012704,
        notes: null,
        ownership: "Комунальна",
        balancer: "ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих м. Миколаєва",
        obj_desc: "Знаходиться на першому поверсі багатоповерхового житлового будинку",
        damag_desc: "Пошкоджено два подвійних фасадних вікна, розмір вікон 2*2 м.кв.",
        rest_cost: "20,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057562368012704,
          46.939546005875115
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 82,
        category: "Заклади культури",
        organ: "Бібліотека-філія № 16 ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих",
        address: "м. Миколаїв, вул. Сергія Цвєтка, 10",
        latitude: 47.0310622,
        longitude: 32.0115286,
        notes: null,
        ownership: "Комунальна",
        balancer: "Адміністрація центрального району ММР",
        obj_desc: "Бібліотека тимчасово знаходиться в Тернівському домі творчості на період капітального ремонту будівлі за адресою: вул. Сергія Цвєтка,10, в",
        damag_desc: "Повністю знищено книжковий фонд бібліотеки внаслідок пожежі. Книжки тимчасово знаходились на зберіганні в складських приміщеннях ТОВ «А",
        rest_cost: "250,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0115286,
          47.0310622
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 83,
        category: "Заклади культури",
        organ: "Бібліотека-філія № 17 ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих",
        address: "м. Миколаїв, просп. Богоявленський, 448",
        latitude: 46.84555754980054,
        longitude: 32.00930908073046,
        notes: null,
        ownership: "Комунальна",
        balancer: "ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих м. Миколаєва",
        obj_desc: "Окремо розташована одноповерхова будівля, газове постачання",
        damag_desc: "Внаслідок обстрілу зруйновано кутову частину будівлі, частину даху, вибиті вікна, численні тріщини по всій будівлі. На території бібліотеки зруйновано дитячий майданчик. Першочергово необхідно провести технічну експертизу будівлі для визначення збитків",
        rest_cost: "100,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00930908073046,
          46.84555754980054
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 84,
        category: "Заклади культури",
        organ: "Бібліотека-філія № 18 ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих",
        address: "м. Миколаїв, просп. Корабелів, 12",
        latitude: 46.856184105433016,
        longitude: 32.01097056039853,
        notes: null,
        ownership: "Комунальна",
        balancer: "ЦМБ ім. М.Л. Кропивницького ЦБС для дорослих м. Миколаєва",
        obj_desc: "Знаходиться на першому поверсі багатоповерхового житлового будинку",
        damag_desc: "10.04.2022 року внаслідок вибухової хвилі вибито два внутрішніх фасадних вікна, що складаються з частин загальним розміром 2,5 на 3,0 кв.м. 07.05.2022 р",
        rest_cost: "105,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01097056039853,
          46.856184105433016
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 85,
        category: "Заклади культури",
        organ: "ММПК \" Молодіжний\"",
        address: "м. Миколаїв, вул. Театральна, 1",
        latitude: 46.94417041878449,
        longitude: 32.038367835880734,
        notes: null,
        ownership: "Комунальна",
        balancer: "ММПК \" Молодіжний\"",
        obj_desc: "Миколаївський міський палац культури \"Молодіжний\"",
        damag_desc: "Заміна скла у вікнах будівлі палацу культури за адресою проспект Богоявленський, 39-А, Повна заміна вікон фасаду будівлі палацу культури за",
        rest_cost: "393,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038367835880734,
          46.94417041878449
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 86,
        category: "Заклади культури",
        organ: "Миколаївський міський палац культури і мистецтв",
        address: "м. Миколаїв, вул. Артилерійська, 7",
        latitude: 46.9791866,
        longitude: 31.9813862,
        notes: "ДОФ (окремо)",
        ownership: "Комунальна",
        balancer: "Миколаївський міський палац культури і мистецтв",
        obj_desc: "Не житлові приміщення, громадська будівля",
        damag_desc: "15.07.22 У результаті ракетної атаки будівля \"Будинку офіцерів флоту\" зазнала значних пошкоджень, а саме: 1. У головній будівлі було пошкоджено",
        rest_cost: "265,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9813862,
          46.9791866
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 87,
        category: "Заклади фізичної культури та спорту",
        organ: "Школа вищої спортивної майстерності 06.03.2022",
        address: "м. Миколаїв, вул. 2 Екіпажна, 123",
        latitude: 46.9861986,
        longitude: 32.0322913,
        notes: null,
        ownership: "Комунальна",
        balancer: "ШВСМ (Фонд коммайна ММР)",
        obj_desc: "Комплексно-веслувальна база ШВСМ",
        damag_desc: "Металопластикові вікна з склопакетами (18 шт.) Покрівля елінгу Металеві двері ігр. зали Автомобіль ГАЗ 53 Човен 1х (2 шт) Човен 4 спецзамовл. (1шт",
        rest_cost: "5 125,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0322913,
          46.98619859999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 88,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 1",
        address: "м. Миколаїв, вул. Театральна, 41-А",
        latitude: 46.9433132,
        longitude: 32.0420658,
        notes: null,
        ownership: "Комунальна",
        balancer: "ДЮСШ № 1",
        obj_desc: "Спортивна споруда",
        damag_desc: "Пошкоджено склопакети 11.07.2022",
        rest_cost: "2 700,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0420658,
          46.9433132
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 89,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 2 12.03.2022 08.05.2022",
        address: "м. Миколаїв, просп. Миру, 17-Г",
        latitude: 46.9444538089897,
        longitude: 32.05203924397543,
        notes: null,
        ownership: "Комунальна",
        balancer: "ДЮСШ № 2",
        obj_desc: "Спортивна зала на 2 поверсі в 2-х поверховій будівлі",
        damag_desc: "Металопластикові вікна з стеклопакетами (5 шт + 1)",
        rest_cost: "120,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05203924397543,
          46.94445380898969
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 90,
        category: "Заклади фізичної культури та спорту",
        organ: "КДЮСШ \"Атлант\" 29.03.2022 04.04.2022",
        address: "м. Миколаїв, просп. Героїв України, 2/4",
        latitude: 46.9806748,
        longitude: 31.9907555,
        notes: null,
        ownership: "Комунальна",
        balancer: "КДЮСШ «Атлант»",
        obj_desc: "1-поверховий спортивний елінг",
        damag_desc: "Розбите скло 50 м. кв. Пошкодження підвісної стелі системи ARMSTRONG Пошкодження металевої труби на пірсі 20 м.",
        rest_cost: "34,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9907555,
          46.9806748
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 91,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 2 29.03.2022",
        address: "м. Миколаїв, вул. Олега Ольжича, 5-А",
        latitude: 46.85109704827597,
        longitude: 32.00523343507366,
        notes: null,
        ownership: "Комунальна",
        balancer: "ДЮСШ № 2",
        obj_desc: "Спортивна зала в підвальному приміщенні в 9-ти поверховій житловій будівлі",
        damag_desc: "Металопластикові вікна з склопакетами (6 шт.)",
        rest_cost: "100,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00523343507366,
          46.85109704827597
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 92,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 2 25.04.2022 23.06.2022",
        address: "м. Миколаїв, вул. Спортивна, 11",
        latitude: 47.04430905981978,
        longitude: 32.02510333288675,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Потребує відновлення вибито - 13+23 вікон, двері, стелі у різних приміщеннях, стіни, фронтони, карнизи, профнастил, утеплювачі та інші пошкодження, пошкоджено покрівлю 50% (40 листів шиферу)",
        rest_cost: "1 714,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02510333288675,
          47.04430905981978
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 93,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 7 12.03.2022",
        address: "м. Миколаїв, вул. Знаменська, 33/4",
        latitude: 46.9147704,
        longitude: 32.0781781,
        notes: null,
        ownership: "Комунальна",
        balancer: "ДЮСШ № 7",
        obj_desc: "Спортивна зала на 2 поверсі в 2-х поверховій будівлі",
        damag_desc: "Металопластикові вікна з склопакетами (11 шт.)",
        rest_cost: "220,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0781781,
          46.91477039999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 94,
        category: "Заклади фізичної культури та спорту",
        organ: "КДЮСШ \"Олімп\" Яхт-клуб",
        address: "м. Миколаїв, вул. Новобудівна 1-Б",
        latitude: 47.0365438,
        longitude: 31.9978033,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управляння у справах фізичної культури і спорту ММР",
        obj_desc: "Спортивна споруда для заняття веслуванням на б/к",
        damag_desc: "07.05.22 Будівля яхт-клубу: 10 вікон спортивної зали, гребний басейн 20 вибитих вікон, частина даху гребного басейну: листи крупнопрофільні 4шт. ширина 1,10 м, довжина 11 метрів, фасад та приліжна територія осколкові пошкодження, зламані ворота. 29.05.22 Осколкові пошкодження прилеглої території, 4 вікна вибити, частково пошкоджена металоконструкція ",
        rest_cost: "1 530,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9978033,
          47.0365438
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 95,
        category: "Заклади фізичної культури та спорту",
        organ: "СК \"Зоря\"",
        address: "м. Миколаїв, вул. Театральна, 10",
        latitude: 46.94568019360008,
        longitude: 32.04254627886359,
        notes: null,
        ownership: "Комунальна",
        balancer: "Комунальна установа центральний міський стадіон",
        obj_desc: "Спортивна споруда",
        damag_desc: "Будівля спорт. комплексу вибито 61 вікон -150м2 . Пробит дах над басейном, пошкодженно 50м2 кровельного покриття. На фут. майданчику з штучним покриттям (96х64)-7 воронок -пошкоджено 30м2 покритя. Футбольний майданчик (20х40) з штучним покриттям-3 вороноки пошкоджено 12м2 покритя. Тенісний корт 1 воронока . Пошкоджено вагончик охорони. Одна воронка на центральній алеїз асфальтним покритям. Пошкоджено металеві урни 5шт., Лавочки - 7 шт. ",
        rest_cost: "600,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04254627886359,
          46.94568019360008
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 96,
        category: "Заклади фізичної культури та спорту",
        organ: "КУ \"Центральний міський стадіон\" акт від 28.06.2022 акт від 15.07.2022 акт від 21.07.2022",
        address: "м. Миколаїв, вул. Спортивна, 1/1",
        latitude: 46.9256779,
        longitude: 31.9803158,
        notes: null,
        ownership: null,
        balancer: "Миколаївська міська рада",
        obj_desc: "?",
        damag_desc: "пошкоджена адмін. будівля (вибиті всі двері вхідні та внутрішні, вікна, пошкоджено навісну стелю, перегородки вибиті, електрокабеля розірвані, на плитах перекриття тріщини, пошкоджено покрівлю, пошкоджено футбол. поле, автоматичний полив, електронне табло, частково бігові доріжки, трибуни для глядачів 1-2 сектор, пошкоджені роздягальні, ВІР ложа, серверна, приміщення для ЗМІ пошкоджено будівлю офісу легкоатлетичного манежу, вибиті вікна, двері, пошкоджено комп’ютерну техніку, пошкоджено зовнішні стіни манежу, пошкоджені приміщення для працівників стадіону(окрема будівля)",
        rest_cost: "23,23k"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9803158,
          46.9256779
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 97,
        category: "Заклади фізичної культури та спорту",
        organ: "ДЮСШ № 1 акт від 21.07.2022",
        address: "м. Миколаїв, вул. Театральна, 41-А",
        latitude: 46.9433132,
        longitude: 32.0420658,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "Спортивна споруда",
        damag_desc: "Пошкоджено склопакети",
        rest_cost: "27,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0420658,
          46.9433132
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 98,
        category: "Об’єкти комунального господарства",
        organ: "МКП \"Миколаївводоканал\"",
        address: "м. Миколаїв, просп. Богоявленський, 60",
        latitude: 46.9223553,
        longitude: 32.0471633,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення обладнання та споруд, які пошкоджені на Галицинівських очисних спорудах каналізації",
        rest_cost: "11 800,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0471633,
          46.9223553
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 99,
        category: "Об’єкти комунального господарства",
        organ: "МКП \"Миколаївводоканал\"",
        address: "м. Миколаїв, вул. Янтарна, 324-Е",
        latitude: 46.8696915,
        longitude: 32.0507368,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення будівель та споруд, які пошкоджені на очисних спорудах водопроводу",
        rest_cost: "945,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0507368,
          46.86969149999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 100,
        category: "Об’єкти комунального господарства",
        organ: "МКП \"Миколаївводоканал\"",
        address: "м. Миколаїв, с. Киселівка Херсонської області",
        latitude: 46.7551837,
        longitude: 32.4096737,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Ремонт (відновлення) ділянки двох водоводів Дніпро-Миколаїв Ду1400мм",
        rest_cost: "8 500,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.4096737,
          46.7551837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 101,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Дунайської флотилії, 5-А",
        latitude: 46.84992387364816,
        longitude: 32.02355026750338,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "48,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02355026750338,
          46.84992387364816
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 102,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Китобоїв, 7-А",
        latitude: 46.94206169154012,
        longitude: 32.06197250849496,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "570,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06197250849496,
          46.94206169154012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 103,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Дмитра Яворницького, 2-В",
        latitude: 46.9318429,
        longitude: 32.0578748,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "42,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0578748,
          46.9318429
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 104,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 140-Ц",
        latitude: 46.96964202893954,
        longitude: 32.07620561529256,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "13,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07620561529256,
          46.96964202893954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 105,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 144-К",
        latitude: 46.969295526013454,
        longitude: 32.079032276361836,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "67,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.079032276361836,
          46.969295526013454
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 106,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Троїцька, 222-К",
        latitude: 46.97202339009239,
        longitude: 32.07389515356018,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "114,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07389515356018,
          46.972023390092396
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 107,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 132-Д",
        latitude: 46.96438140987303,
        longitude: 32.07443733015751,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "17,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07443733015751,
          46.96438140987303
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 108,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Миколаївська, 34-Б",
        latitude: 46.943262615876094,
        longitude: 32.049136386357596,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "76,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.049136386357596,
          46.943262615876094
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 109,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Новозаводська, 48",
        latitude: 46.969079034915104,
        longitude: 32.085790371549166,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "437,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.085790371549166,
          46.969079034915104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 110,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Знам’янська, 2-К",
        latitude: 46.916627634788945,
        longitude: 32.078277155726006,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "464,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.078277155726006,
          46.916627634788945
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 111,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Героїв України, 21-А/1",
        latitude: 46.9957884,
        longitude: 31.998295,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "241,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998295,
          46.9957884
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 112,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Героїв України, 91",
        latitude: 47.012240867162205,
        longitude: 31.996939162976897,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "103,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996939162976897,
          47.012240867162205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 113,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Героїв України, 72",
        latitude: 47.0050515,
        longitude: 31.9968626,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "38,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9968626,
          47.0050515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 114,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Малко-Тирнівська, 1",
        latitude: 47.016920581860376,
        longitude: 31.998536954900743,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "156,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998536954900743,
          47.016920581860376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 115,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. 2 Екіпажна, 5-А",
        latitude: 46.97338627006836,
        longitude: 32.018856043855656,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "5,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018856043855656,
          46.97338627006836
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 116,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. 2 Екіпажна, 4-К",
        latitude: 46.97421232578435,
        longitude: 32.01875003438719,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "11,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01875003438719,
          46.97421232578435
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 117,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. 2- Екіпажна, 4-І",
        latitude: 46.973885099078515,
        longitude: 32.018444851682375,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "122,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018444851682375,
          46.97388509907851
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 118,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Біла, 71",
        latitude: 46.9564453,
        longitude: 31.943664,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "42,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943664,
          46.9564453
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 119,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Чорноморська, 1",
        latitude: 46.989647916581745,
        longitude: 31.947859660540693,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "37,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947859660540693,
          46.989647916581745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 120,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Генерала Карпенко, 51-А",
        latitude: 46.96316126504384,
        longitude: 31.952793338284415,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "20,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952793338284415,
          46.96316126504384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 121,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Курортна, 11-А",
        latitude: 46.9556677,
        longitude: 31.954547603973722,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "38,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954547603973726,
          46.955667700000014
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 122,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Богоявленський, 325/4",
        latitude: 46.851042,
        longitude: 32.0084762,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "5,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0084762,
          46.851042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 123,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Корабелів, 2-А",
        latitude: 46.8551707,
        longitude: 32.0046799,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "14,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00467990000001,
          46.8551707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 124,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Янтарна, 67",
        latitude: 46.8731608,
        longitude: 32.0220127,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "19,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0220127,
          46.8731608
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 125,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Глінки, 8-К",
        latitude: 46.8669956,
        longitude: 32.0118257,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "48,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0118257,
          46.8669956
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 126,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Рибна, 1-І",
        latitude: 46.861431,
        longitude: 32.0126674,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0126674,
          46.861431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 127,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Олега Григор’єва, 2-В",
        latitude: 46.9751953350689,
        longitude: 31.96329752325842,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "11,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96329752325842,
          46.9751953350689
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 128,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 146-В",
        latitude: 46.97072395040104,
        longitude: 32.077953765944585,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077953765944585,
          46.97072395040104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 129,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Новобугська, 101",
        latitude: 46.9722308,
        longitude: 32.0758624,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "2,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0758624,
          46.9722308
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 130,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Новобугська, 128",
        latitude: 46.970955647094534,
        longitude: 32.077317889474266,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "2,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077317889474266,
          46.970955647094534
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 131,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Миру, 19",
        latitude: 46.94364033161968,
        longitude: 32.05176333140376,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "15,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05176333140376,
          46.94364033161968
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 132,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, просп. Миру, 21",
        latitude: 46.9429797,
        longitude: 32.053937,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053937,
          46.9429797
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 133,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Театральна, 10-К",
        latitude: 46.94659656906731,
        longitude: 32.04576187235524,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "7,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04576187235524,
          46.94659656906731
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 134,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 146-В",
        latitude: 46.9706442815102,
        longitude: 32.077884881797935,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "43,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077884881797935,
          46.970644281510204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 135,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Електронна, 61",
        latitude: 46.97055708481206,
        longitude: 32.07145054281714,
        notes: "на ЦТП 135-3",
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "48,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07145054281714,
          46.97055708481206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 136,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 132-Д",
        latitude: 46.96413281306569,
        longitude: 32.07419448435233,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "6,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07419448435233,
          46.96413281306569
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 137,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Олега Ольжича, 5-Б",
        latitude: 46.851251260418536,
        longitude: 32.00644032288638,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "128,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00644032288638,
          46.851251260418536
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 138,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Дмитра Яворницького, 2-А",
        latitude: 46.9328491,
        longitude: 32.059646,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "6,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059646,
          46.9328491
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 139,
        category: "Об’єкти комунального господарства",
        organ: "ОКП \"Миколаївоблтеплоенерго\"",
        address: "м. Миколаїв, вул. Космонавтів, 146-А",
        latitude: 46.9699380305728,
        longitude: 32.07851525420812,
        notes: null,
        ownership: "Комунальна",
        balancer: "?",
        obj_desc: "?",
        damag_desc: "Відновлення об'єкту теплопостачання",
        rest_cost: "15,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07851525420812,
          46.9699380305728
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 140,
        category: "Об’єкти комунального господарства",
        organ: "КП ММР \"Миколаївелектротранс\" (НТТУ) 15.07.2022",
        address: "м. Миколаїв, вул. Андрєєва, 17",
        latitude: 46.96273103536465,
        longitude: 31.975778422591127,
        notes: null,
        ownership: "Комунальна",
        balancer: "Миколаївська міська рада",
        obj_desc: "?",
        damag_desc: "вибиті - 55 вікон, пошкоджено більше 11 одиниць техніки",
        rest_cost: "1 950,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975778422591127,
          46.96273103536465
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 141,
        category: "Інфраструктура, інше",
        organ: "Адміністративна будівля акт від 02.06.2022",
        address: "м. Миколаїв, вул. Інженерна, 1-А",
        latitude: 46.9717486,
        longitude: 32.0028208,
        notes: null,
        ownership: "Комунальна",
        balancer: "Адміністрація Центрального району Миколаївської міської ради",
        obj_desc: "Двоповерховий будинок",
        damag_desc: "Будівля зазнала великої деформації конструктиву у вигляді наскрізних тріщин. Пошкодження конькової частини основної будівлі, вікна з мет",
        rest_cost: "2 000,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0028208,
          46.9717486
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 142,
        category: "Інфраструктура, інше",
        organ: "Архівний відділ Миколаївської районної державної адміністрації дпз22.06.2022",
        address: "м. Миколаїв, вул. Чорноморська, 4",
        latitude: 46.98996332507081,
        longitude: 31.94736155084112,
        notes: null,
        ownership: null,
        balancer: "48-08-39 +380973808228 (п.903)",
        obj_desc: "Адміністративна будівля, 2 поверхи, загальна площа 740,4 кв.м., кам’яний будинок з шиферним покриттям",
        damag_desc: "Пошкоджені дерев’яні подвійні вікна (30 од.), шифер (2 листи) і димохід",
        rest_cost: "2 630,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94736155084112,
          46.98996332507081
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 143,
        category: "Інфраструктура, інше",
        organ: "Будівля громадської міської лазні \"Банний двір\"",
        address: "м. Миколаїв, вул. Пушкінська, 71/А",
        latitude: 46.961322027280005,
        longitude: 31.976215464433746,
        notes: null,
        ownership: "Державна",
        balancer: "Український державний університет науки і технологій",
        obj_desc: "?",
        damag_desc: "вибиті-6 дверей вхідних, 5- вікон, в 7-ми приміщеннях пошкоджена стеля (гіпсокартон та натяжна), пошкоджена покрівля(шифер, профнастіл), фасад будівлі(декоративна штукатурка), пошкоджено фундамент, тріщіни постінах, пошкоджені світильники вуличні та в приміщеннях.",
        rest_cost: "900,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976215464433746,
          46.961322027280005
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 144,
        category: "Інфраструктура, інше",
        organ: "Заводського РТЦК",
        address: "м. Миколаїв, вул. Пушкінська, 69-А",
        latitude: 46.9621009,
        longitude: 31.9772903,
        notes: null,
        ownership: null,
        balancer: "?",
        obj_desc: "?",
        damag_desc: "вибиті- 40 вікон, двері пошкоджено покрівлю (шифер- 150 листів)",
        rest_cost: "1 050,00"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9772903,
          46.9621009
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 145,
        category: "Заклади освіти",
        organ: "ЗЗСО №7",
        address: "м. Миколаїв, вул. Потьомкінська, 45/47",
        latitude: 46.971615622180835,
        longitude: 31.98663094091934,
        notes: null,
        ownership: "Комунальна",
        balancer: "Управління освіти ММР",
        damag_desc: "Значні пошкодження будівлі",
        rest_cost: "16 000,00",
        images: [
            "school_7-1.jpg",
            "school_7-2.jpg",
            "school_7-3.jpg",
            "school_7-4.jpg",
            "school_7-5.jpg",
        ]
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98663094091934,
          46.971615622180835
        ]
      }
    }
  ]
}