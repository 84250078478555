import L from "leaflet";

import socialSVG from "../images/svg/icon-social_20x21.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [20, 20],
        tooltipAnchor: [10, -10]
    },
});

// @ts-ignore
export const socialIcon = new LeafIcon({iconUrl: socialSVG});
