import React from "react";

import L from "leaflet";
import {MapContainer, TileLayer} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';

// import {markers} from "../data/markers";
// import {polylineList} from "../data/polylines";
// import {polygonsList} from "../data/polygons";

import {niko_damage_data} from "../data/niko-damage-data";

import {LayerNikoDamage} from "../layers/LayerNikoDamage";

export const Map = () => {
    const position: L.LatLngExpression = [46.95355352880178, 32.03006707746631];

    return (
        <MapContainer center={position}
                      zoom={12}
                      minZoom={12}
                      maxZoom={18}
                      scrollWheelZoom={true}
                      zoomControl={true}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/*<LayerMarkers data={markers}/>*/}
            {/*<LayerPolyline data={polylineList}/>*/}
            {/*<LayerPolygons data={polygonsList}/>*/}
            <MarkerClusterGroup showCoverageOnHover={false}>
                <LayerNikoDamage data={niko_damage_data}/>
            </MarkerClusterGroup>
        </MapContainer>
    );
};
