import L from "leaflet";

import sportSVG from "../images/svg/icon-sport_20x21.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [20, 20],
        tooltipAnchor: [10, -10]
    },
});

// @ts-ignore
export const sportIcon = new LeafIcon({iconUrl: sportSVG});
